import React from 'react';

const PobaPage = () => {
  return (
    <div style={styles.container}>
      {/* 이미지와 버튼을 감싸는 div */}
      <div style={styles.imageContainer}>
        <img 
          src="/poba.jpg" 
          alt="POBA 모바일영화제" 
          style={styles.image} 
        />
        {/* 투명한 클릭 가능한 버튼 */}
        <a 
          href="https://poba30.com/" 
          target="_blank" 
          rel="noopener noreferrer" 
          style={styles.invisibleButton}
        >
          출품작 구경하기
        </a>
      </div>
    </div>
  );
};

// 스타일 정의
const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative',
  },
  imageContainer: {
    position: 'relative', // 부모 요소에 relative
    display: 'inline-block',
  },
  image: {
    width: '100%',
    display: 'block',
  },
  invisibleButton: {
    position: 'absolute', // 버튼을 이미지 위에 겹치기
    bottom: '10%', // 버튼의 위치 조정 (사진의 실제 버튼 위치에 맞춤)
    left: '5%', // 버튼의 가로 위치 조정
    width: '90%', // 버튼의 폭을 사진 버튼 크기와 맞춤
    height: '6%', // 버튼의 높이를 사진 버튼 크기와 맞춤
    opacity: 0, // 버튼을 보이지 않게 설정
    pointerEvents: 'all', // 클릭 가능하게 유지
    cursor: 'pointer', // 커서가 포인터로 변경
  },
};

export default PobaPage;
